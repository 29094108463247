<template>
	<div class="row">
		<div v-if="isReady" class="col-12">


			<div class="col-12 text-right mb-5">
				<b-button @click="addEmbryon">
					<font-awesome-icon :icon="['fal', 'plus']" />
					{{ $t('gynecologie.embryon.ajouter') }}
				</b-button>

				<b-button @click="reset" class="ml-2">
					<font-awesome-icon :icon="['fal', 'sync-alt']" />
					{{ $t('gynecologie.embryon.reset') }}
				</b-button>
			</div>
			<div
				v-for="embryon in embryons"
				:key="embryon.id"
				class="col-12 list_embryons"
			>	
				<div>
					<div class="row align-items-center">
						<div class="col-xl-auto col-12">
							<div class="embryo_number">{{ $t('gynecologie.embryon.numero')}} {{ embryon.embryon_num > 0 ? embryon.embryon_num : '...' }}</div>
						</div>
						<div class="col-auto order-lg-last embryo_delete">
							<b-button variant="secondary" @click="deleteEmbryon(embryon.embryon_id)">
								<font-awesome-icon :icon="['fal', 'trash-alt']" />
							</b-button>
						</div>
						<div class="col-auto">
							<b-checkbox v-model="embryon.embryon_congele" class="mr-3 d-inline">
								{{ $t('gynecologie.embryon.congele')}}
							</b-checkbox>

							<b-checkbox v-model="embryon.embryon_detruit" class="d-inline">
								{{ $t('gynecologie.embryon.detruit')}}
							</b-checkbox>
						</div>
						<div class="col-12 mt-3 mt-lg-0 col-lg">
							<b-input-group :prepend="$t('gynecologie.embryon.commentaire')">
								<b-input v-model="embryon.embryon_commentaire" />
							</b-input-group>
						</div>
					</div>
					<div class="row mt-2 align-items-center mx-lg-5">
						<div class="col-auto">
							<b-input-group :prepend="$t('gynecologie.embryon.taille')">
								<b-input v-model="embryon.embryon_taille" />
							</b-input-group>
						</div>
						<div class="mt-3 mt-lg-0 col-lg">
							<b-input-group :prepend="$t('gynecologie.embryon.stade')">
	                            <e-select
	                                v-model="embryon.embryon_stade"
	                                :placeholder="getTrad('gynecologie.embryon.select_stade')"
	                                :options="all_stades"
	                                :searchable="true"
	                                :show-labels="false"
								>
									<template slot="option" slot-scope="{ option }">{{ $t('gynecologie.embryon.'+option) }}</template>
									<template slot="singleLabel" slot-scope="{ option }">{{ $t('gynecologie.embryon.'+option) }}</template>
                                	<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>
							</b-input-group>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
		        <div class="text-center mt-5">
					<b-button
						variant="primary"
						@click="submit"
						:disabled="processing"
					>
						<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
						<font-awesome-icon v-else :icon="['fal', 'check']" class="mr-1"/>
						{{ $t('global.valider') }}
					</b-button>
				</div>
			</div>
		</div>

		<LoadingSpinner v-else />
	</div>
</template>

<script>
import Common from '@/assets/js/common'
import Actes from '@/mixins/Actes'
import Embryon from '@/mixins/Embryon'
import Shutter from '@/mixins/Shutter'
import { EventBus } from 'EventBus'

export default {
	name: 'AjoutEmbryon',
	mixins: [Actes, Embryon, Shutter],
	props: {
		actes_ids: { type: Array, default: () => [] },
	},
	data: () => ({
		isReady: false,
		processing: false,
		embryonsSaved: [],
		embryons: [],
		acte: null,
		all_stades: ['-','MOR','JBL','BLA','BLE']
	}),
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.isReady = false

			this.embryonsSaved = await this.getCollecteEmbryons(this.actes_ids[0])
			this.embryons = this.embryonsSaved.concat([])

			this.acte = await this.getActeFromId(this.actes_ids[0], 'lite')

			this.isReady = true
		},
		reset() {
			this.embryons = this.embryonsSaved.concat([])
		},
		getDefaultEmbryon() {
			return {
				embryon_id: -Common.getRandomInt(9999999),
				embryon_congele: false,
				embryon_detruit: false,
				embryon_commentaire: null,
				embryon_taille: 0,
				embryon_stade: '-'
			}
		},
		addEmbryon() {
			this.embryons.push(this.getDefaultEmbryon())
		},
		deleteEmbryon(embryon_id) {
			const index = this.embryons.findIndex((emb) => (emb.embryon_id === embryon_id))
			if(index !== -1) {
				this.embryons.splice(index, 1)
			}
		},
		async submit() {
			try {
				this.processing = true

				await this.upsertEmbryons(this.actes_ids[0], this.embryons)

				if(this.$route.name === 'HorseGynecologie') {
					this.shutterPanel().close('add_embryon')
					this.shutterPanel().reload('horse-gyneco')
				}
				else {
					this.$router.push({
						name: 'HorseGynecologie',
						params: { horse_id: this.acte.actes_horse }
					})
				}

				// Pour mettre à jour l'état physio, on force la synchro
				await this.$sync.force(true)
				EventBus.$emit('GynecologieHorse::reload')
				EventBus.$emit('HorseFiche::refreshHorseStatus')
			}
			finally {
				this.processing = false
			}
		}
	},
	components: {
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
}
</script>
